import React from "react";
// import useEffect
import { useEffect } from "react";
import AllRoutes from "./router/routes";
import { Provider } from "react-redux";
import { store } from "../src/store/store";
import FCM from "../src/library/FCM";
import { Widget, addResponseMessage } from 'react-chat-widget';

import { STRING_CONSTANTS } from "./utils/constants/stringConstants";
import { checkFeatureFlag } from "./utils/UtilityFunctions";
import { cookieService, userService } from "./_services";

import 'react-chat-widget/lib/styles.css';
import './customWidget.css';

// I now want to override .rcw-widget-container to have a higher z-index

//import logo from './logo.svg';

const App = (props) => {
  useEffect(() => {
    addResponseMessage('Welcome to MPLOI, how can I help you?');
  }, []);

  const userTypeCookieval = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
  const ffChatbot = checkFeatureFlag(userTypeCookieval, 'chatbot');
  const chatMessages = [];
  
  const handleNewUserMessage = (newMessage) => {
    chatMessages.push({
      content: newMessage,
      role: 'user',
    });
    const params = {
      'method': 'chatbot-msg',
      data: {messages: chatMessages},
    };
    userService.aiRequest(params, true)
      .then(data => {
        const msg = data?.data?.msg || '';
        if (msg) {
          chatMessages.push({
            content: msg,
            role: 'assistant',
          });
          addResponseMessage(msg);
        }
      })
      .catch(e => console.log(e))
  };

  return (
    <Provider store={store}>
      {/* <Elements stripe={stripePromise} options={options as StripeElementsOptions}> */}
      <AllRoutes />

      <FCM {...props} />
      {/* </Elements> */}
      {ffChatbot && (
        <Widget
          handleNewUserMessage={handleNewUserMessage}
          // profileAvatar={"https://staging.mploi.com.au/assets/images/db-logo.svg"}
          title="MPLOI Assistant"
          subtitle="Ask questions about the MPLOI platform."
          style={{ zIndex: 9999999, position: 'relative' }}
        />
      )}

    </Provider>
  );
};

export default App;
