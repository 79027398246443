import React, { useEffect, useRef, useState } from "react";
import { api } from "../../../middleware/api";
import {
  UtilityFunctions,
} from "../../../utils/UtilityFunctions";
import { actionCreators, State } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { cookieService } from "../../../_services";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import NoVerifyModal from "../../../components/noVerifyModal";
const debounce = require("debounce-promise");
const Courses = (props: any) => {
  const dispatch = useDispatch();
  const {
    setSidebar,
    setResetJob,
  } = bindActionCreators(actionCreators, dispatch);
  const reduxState = useSelector((state: State) => state);
  const state = useSelector((state: State) => state);
  const scrollRef = useRef<any>();
  const [nextURL, setNextURL] = useState("");
  const [activeCourseList, setActiveCourseList] = useState([]);
  const [currentCourse, setCurrentCourse] = useState<any>();
  const [scrollWidth, setScrollWidth] = useState<any>();
  const [notVerifiedModal, setNotVerifiedModal] = useState(false);
  const verified = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
    ?.user?.isVerified;

  useEffect(() => {
    !props.isUserLoggedIn && checkLocationPermission();
  }, [props.coords]);

  async function checkLocationPermission() {
    document.body.classList.add("loading-indicator");
    await navigator.permissions
      .query({ name: "geolocation" })
      .then((result) => {
        if (result.state === "granted" && props?.coords) {
          getMatchedCourses();
          // setLocationPermission(true)
        } else if (result.state === "prompt") {
          getMatchedCourses();
          // setLocationPermission(false)
        } else if (result.state === "denied") {
          getMatchedCourses();
          // setLocationPermission(false)
        }
      });
  }

  useEffect(() => {
    setResetJob(null);
  }, []);

  const getMatchedCourses = () => {
    if (props?.isUserLoggedIn) {
        if (props.userType === STRING_CONSTANTS.userType.employee) {
            setNextURL("");
            api
                .post(
                    `/employee/courses/?limit=10`,
                    null
                )
                .then((res: any) => {
                    setActiveCourseList(res?.data);
                    res?.data?.next != null && setNextURL(res?.data?.next);
                })
                .catch((e) => {
                    setActiveCourseList([]);
                });

            // @TODO: set no loading on this mode...
            api
              .post(
                  `/employee/courses_u/?limit=10`,
                  null
              )
              .then((res: any) => {
                  console.log("Length of them (update...)", res?.data?.length, res?.data);
                  setActiveCourseList(res?.data);
                  res?.data?.next != null && setNextURL(res?.data?.next);
              })
              .catch((e) => {
                console.log("Error updating courses...", e);
              });
        } else {
            console.log('No courses for non employee')
        }
    }
  };

  const handleScroll = () => {
    var store = scrollRef?.current;
    if (store) {
      const { scrollLeft = 0, scrollWidth = 0, clientWidth = 0 } = store;
      setScrollWidth(scrollWidth + 1);
      let isAtRightEnd = scrollLeft + clientWidth + 1 >= scrollWidth;
      // when user hits the end of scroll, API for the next set of results(nextURL) is called
      if (
        nextURL &&
        (scrollLeft + clientWidth === scrollWidth || isAtRightEnd)
      ) {
        const nextPage = nextURL?.split(UtilityFunctions.API_URL())?.[1];
        if (!props?.isUserLoggedIn) {
          api.post(nextPage, null).then((res: any) => {
            setNextURL(res.data.next);
            setActiveCourseList([
              ...activeCourseList,
              ...res?.data?.results,
            ]);
          });
        } else if (props.userType === STRING_CONSTANTS.userType.employee) {
          document.body.classList.add("loading-indicator");
          api.post(nextPage, null).then((res: any) => {
            setNextURL(res.data.next);
            setActiveCourseList([
              ...activeCourseList,
              ...res?.data?.results,
            ]);
          });
        }
      }
    }
  };

  const debounceClick = debounce(() => {
    handleScroll()
  }, 600);
  const debounceClickButtonLeft = debounce(() => {
    scrollRef.current.scroll({
      left: scrollRef?.current?.scrollLeft - 700,
      behavior: "smooth",
    });
  }, 100);
  const debounceClickButtonRight = debounce(() => {
    scrollRef.current.scroll({
      left: scrollRef?.current?.scrollLeft + 700,
      behavior: "smooth",
    });
  }, 100);

  useEffect(() => {
    getMatchedCourses();
  }, []);

  useEffect(() => {
    setCurrentCourse(
      activeCourseList?.find((job) => job?.id === props?.selectedCourseID)
    );
  }, [activeCourseList, props?.selectedCourseID]);

  const handleViewCourse = (course) => {
    if (course['permalink']) {
      window.open(course['permalink'], '_blank');
    }
  };

  const renderCoursesList = activeCourseList?.map(
    (course, index) => {
      return (
        // color hear employee background color change
        // light-gray-bg
        // light-perple-bg
        <div
          className={`employee-card`}
          key={course?.id}
        >
          <div
            className="photo-wrap"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            onClick={() => {
              setSidebar({
                sidebar_name: "employee-details",
                show_sidebar: true,
              });
              // @TODO: this should be our course sidebar here...
              props?.setSelectedEmployee(course);
            }}
          >
            <img
              src={course?.image?.url}
            />
            <div className="over-content">
              <div className="content-top">
                <div className="left-name">
                  {/* <h3>{course?.name}</h3> */}
                  <h2></h2>
                </div>
              </div>
              <div className="content-bottom">
                <h4>{course?.name}</h4>
                <h5>
                  {/* <img
                    src={
                      UtilityFunctions.getImageURL() + "Globe Location.svg"
                    }
                    className="global-lock"
                    alt=""
                  /> */}
                  <ul>
                    {course?.reasons.map((reason) => {
                      return (
                        <li key={reason}>{reason}</li>
                      );
                    })}
                  </ul>
                </h5>
              </div>
            </div>
          </div>
          <div className="button-wrap">
            <div className={`btn-effect full-width-btn`}>
              <button
                className="chat-btn"
                type="button"
                onClick={() => {
                  handleViewCourse(course);
                }}
                style={{ color: "black" }}
              >
                View Course
              </button>
            </div>
          </div>
        </div>
      );
    }
  );
  const handleClose = () => {
    setNotVerifiedModal(false);
  };
  return (
    <>
      <NoVerifyModal show={notVerifiedModal} handleClose={handleClose} />
      <div className="employe-wrap">
        {activeCourseList?.length ? (
          <div className="employe-gradient">
            <div
              className="employe-scroll"
              ref={scrollRef}
              onScroll={() => debounceClick()}
            >
              {!props?.isUserLoggedIn ? (
                <>
                  {renderCoursesList}
                </>
              ) : !verified ? (
                <>
                  {renderCoursesList}{" "}
                </>
              ) : (
                <>
                  {props.userType === STRING_CONSTANTS.userType.employee && (
                    <div className="myforst-card-setup update-match text-center">
                      <div className="inner-first-data">
                        <h5>Courses suitable for you</h5>
                        <p>
                          We have used MPLOI AI to find the courses which relate to skills which will best improve your prospects in getting jobs in your industry.
                        </p>
                      </div>
                    </div>
                  )}
                  {renderCoursesList}

                </>
              )}
            </div>
            {/* {(verified || !props?.isUserLoggedIn) ? */}
            <div className="employe-gradient-arrow-button">
              <div
                onClick={() => {
                  debounceClickButtonLeft()
                }}
              >
                <img
                  src={
                    UtilityFunctions.getImageURL() + "chevron-arrow-left.svg"
                  }
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  debounceClickButtonRight()

                }}
              >
                <img
                  src={
                    UtilityFunctions.getImageURL() + "chevron-arrow-right.svg"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="no-table-data no-table-one">
            {props.userType === STRING_CONSTANTS.userType.employer ? (
              <div>
                <div className="arrow-inner">
                  <img
                    src={UtilityFunctions.getImageURL() + "pointing-arrow.png"}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src={UtilityFunctions.getImageURL() + "no-data.svg"}
                    alt=""
                  />
                  <p>
                    It looks like no courses are suitable for you right now.
                  </p>
                </div>
              </div>
            ) : (
              <div className="employee-empty">

                <div>
                  <img
                    src={UtilityFunctions.getImageURL() + "no-data.svg"}
                    alt=""
                  />
                  <p>It looks like there are no matching courses yet.</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Courses;
